import Container from "react-bootstrap/Container"
import React from "react"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import NavDropdown from "react-bootstrap/NavDropdown"
import { LinkContainer } from "react-router-bootstrap"
import { useLocation } from "react-router-dom"

const Layout = (props: { children: React.ReactNode }) => {
  const location = useLocation()

  return (
    <>
      <Container className="py-1 d-none d-md-block">
        <LinkContainer exact to="/">
          <Navbar.Brand>Pulitz Work</Navbar.Brand>
        </LinkContainer>
        <span>~ Showcase for something great ~</span>
      </Container>
      <Navbar expand="md" sticky="top" bg="dark" variant="dark">
        <Container>
          <Navbar.Brand className="d-md-none" href="#home">
            Pulitz Work
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav activeKey={location.pathname}>
              <LinkContainer exact to="/">
                <Nav.Link>Home</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/about">
                <Nav.Link>About</Nav.Link>
              </LinkContainer>
              <NavDropdown title="Showcases" id="collasible-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">
                  Coming Soon
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Coming Soon
                </NavDropdown.Item>
              </NavDropdown>
              <LinkContainer to="/contact">
                <Nav.Link>Contact</Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className="py-1" style={{ height: "200vh" }}>
        <div>{props.children}</div>
      </Container>
    </>
  )
}

export default Layout
