import React from "react"
import "./App.scss"
import Layout from "./components/layouts/Layout"
import Routes from "./routes"

function App() {
  return (
    <Layout>
      <Routes />
    </Layout>
  )
}

export default App
